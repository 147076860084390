import Link from 'next/link'

export default function Footer() {
  return (
    <footer>
      <div className="relative mx-auto max-w-7xl px-6 py-12 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          <Link href="/privacy" className="text-center text-xs leading-5 text-gray-500">
            <span>Privacy Policy</span>
          </Link>
          <Link href="/terms" className="text-center text-xs leading-5 text-gray-500">
            <span>Terms of Use</span>
          </Link>
        </div>
        <div className="mt-8 md:order-1 md:mt-0">
          <p className="text-center text-xs leading-5 text-gray-500">
            &copy; {new Date().getFullYear()} Mirror AI, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
