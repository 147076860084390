import { createSubscriberAPI } from '@/api/subscriber'
import { CheckCircleIcon, EnvelopeIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid'
import { useMutation } from '@tanstack/react-query'
import Link from 'next/link'
import { useForm } from 'react-hook-form'

export default function Newsletter() {
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: email => {
      return createSubscriberAPI(email)
    },
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm()

  const onSubscribe = data => {
    mutate(data.email, {
      onSuccess: () => {
        reset() // Reset form fields on success
      },
    })
  }

  return (
    <div className="relative py-16">
      <div className="mx-auto grid max-w-7xl grid-cols-1 gap-10 px-6 lg:grid-cols-12 lg:gap-8 lg:px-8">
        <div className="max-w-xl text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl lg:col-span-7">
          <h2>Want product news and updates?</h2>
          <p>Sign up for our newsletter.</p>
        </div>
        <form
          className="w-full max-w-md lg:col-span-5 lg:pt-2"
          onSubmit={handleSubmit(onSubscribe)}
        >
          <div className="flex rounded-md shadow-sm gap-x-4">
            <div className="relative flex-grow focus-within:z-10">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </div>

              <input
                type="email"
                name="email"
                id="email"
                className="w-full flex-auto rounded-md border-0 pl-10 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder="Enter your email"
                disabled={isPending || isSuccess}
                aria-invalid={errors.email ? 'true' : 'false'}
                aria-describedby="email-error"
                {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
              />
              {errors.email && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true" />
                </div>
              )}
            </div>
            <button
              type="submit"
              className={`inline-flex items-center gap-x-2 rounded-md px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm ${
                isPending ? 'bg-gray-600' : 'bg-indigo-600 hover:bg-indigo-500'
              } focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
              disabled={isPending || isSuccess}
            >
              {isPending ? (
                <>
                  <svg
                    className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  Loading...
                </>
              ) : isSuccess ? (
                <>
                  Subscribed!
                  <CheckCircleIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
                </>
              ) : (
                <>
                  Subscribe
                  <span aria-hidden="true">&rarr;</span>
                </>
              )}
            </button>
          </div>
          {errors.email && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              Not a valid email address.
            </p>
          )}
          <p className="mt-4 text-sm leading-6 text-gray-900">
            We care about your data. Read our{' '}
            <Link href="/privacy" className="font-semibold text-indigo-600 hover:text-indigo-500">
              Privacy Policy.
            </Link>
          </p>
        </form>
      </div>
    </div>
  )
}
