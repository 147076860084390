import garyInput from '../../../../../public/images/pages/landing/users/gary_tan_input.webp'
import garyOutput from '../../../../../public/images/pages/landing/users/gary_tan_output.webp'
import ishaInput from '../../../../../public/images/pages/landing/users/isha_input.webp'
import ishaOutput from '../../../../../public/images/pages/landing/users/isha_output.webp'
import prateekInput from '../../../../../public/images/pages/landing/users/prateek_input.webp'
import prateekOutput from '../../../../../public/images/pages/landing/users/prateek_output.webp'
import radhikaInput from '../../../../../public/images/pages/landing/users/radhika_input.webp'
import radhikaOutput from '../../../../../public/images/pages/landing/users/radhika_output.webp'
import ImagePair from './ImagePair'

const outputs = [
  { input: radhikaInput, output: radhikaOutput, alt: 'radhika' },
  { input: prateekInput, output: prateekOutput, alt: 'prateek' },
  { input: ishaInput, output: ishaOutput, alt: 'isha' },
  { input: garyInput, output: garyOutput, alt: 'gary_tan' },
]

export default function Outputs() {
  return (
    <div className="relative overflow-hidden py-24" id="outputs">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h2 className="text-base font-semibold leading-7 text-indigo-600">Everything you need</h2>
          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Just A Few Steps Away from Stunning Photographs!
          </p>
        </div>
      </div>

      <div className="mx-auto gap-4 p-2 pt-24 sm:flex sm:flex-wrap sm:justify-center sm:gap-8">
        {outputs.map((user, index) => (
          <ImagePair key={index} inputSrc={user.input} outputSrc={user.output} altTag={user.alt} />
        ))}
      </div>
    </div>
  )
}
