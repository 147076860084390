const ENDPOINTS = {
  // User endpoints
  USERS: '/users/',
  USERS_ME: '/users/me',
  CREATE_USER_IMAGES: '/images/',
  // Subscriber endpoints
  CREATE_SUBSCRIBER: '/subscriber/',
  // Razorpay endpoints
  CREATE_ORDER: '/razorpay/order/',
  VERIFY_PAYMENT: '/razorpay/verify/',
}

export default ENDPOINTS
