import { HomeIcon, PhotoIcon } from '@heroicons/react/24/outline'

export const LANDING_PAGE_NAVIGATION = [
  { name: 'AI Portraits', href: '#outputs' },
  { name: 'Features', href: '#features' },
  { name: 'Pricing', href: '#pricing' },
]

export const DASHBOARD_NAVIGATION = [
  { name: 'Dashboard', href: '/app/dashboard', icon: HomeIcon },
  { name: 'Gallery', href: '/app/gallery', icon: PhotoIcon },
]

export const SUPPORT_NAVIGATION = [{ id: 2, name: 'Help', href: '#', initial: 'H' }]
