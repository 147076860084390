const PricingPlan = {
  BASIC: 'BASIC',
  PRO: 'PRO',
  PREMIUM: 'PREMIUM',
}

const Currency = {
  INR: { code: 'INR', symbol: '₹' },
  USD: { code: 'USD', symbol: '$' },
}

export const pricingConfig = {
  [PricingPlan.BASIC]: {
    [Currency.INR.code]: { amount: 1500, symbol: Currency.INR.symbol },
    [Currency.USD.code]: { amount: 20, symbol: Currency.USD.symbol },
  },
  [PricingPlan.PRO]: {
    [Currency.INR.code]: { amount: 3000, symbol: Currency.INR.symbol },
    [Currency.USD.code]: { amount: 40, symbol: Currency.USD.symbol },
  },
  [PricingPlan.PREMIUM]: {
    [Currency.INR.code]: { amount: 4500, symbol: Currency.INR.symbol },
    [Currency.USD.code]: { amount: 60, symbol: Currency.USD.symbol },
  },
}

export const pricingPlans = [
  {
    name: 'Basic',
    id: 'tier-basic',
    href: '/auth/register',
    price: '$20',
    description: 'Ideal for personal use and exploration.',
    brief: 'Get 50 standard portraits with standard clothing and backgrounds styles.',
    features: [
      '1 Model Training Session',
      '50 Standard Portraits',
      'Standard clothing styles',
      'Standard backgrounds',
      'Standard processing times',
    ],
  },
  {
    name: 'Pro',
    id: 'tier-pro',
    href: '/auth/register',
    price: '$40',
    description: 'Ideal for enthusiasts and creators.',
    brief: 'Get 100 standard portraits with 10 custom clothing and 10 backgrounds styles.',
    features: [
      '1 Model Training Session',
      '100 Standard Portraits',
      '10 Custom Clothing',
      '10 Custom Backgrounds',
      'Priority processing',
    ],
    mostPopular: true,
  },
  {
    name: 'Premium',
    id: 'tier-premium',
    href: '/auth/register',
    price: '$60',
    brief: 'Get 200 standard portraits with 30 custom clothing and backgrounds styles.',
    description: 'Ideal for professionals and businesses.',
    features: [
      '1 Model Training Session',
      '200 Standard Portraits',
      '30 Custom Clothing',
      '30 Custom Backgrounds',
      'Dedicated Support',
      'Highest priority processing',
    ],
    bestValue: true,
  },
]
