import Image from 'next/image'

const labels = ['Real', 'Mirror AI']

const ImageContainer = ({ src, alt, label }) => (
  <div className="relative h-72 w-72">
    <Image src={src} alt={alt} className="rounded-2xl object-cover" fill />
    <div className="absolute bottom-0 left-0 rounded-md bg-gradient-to-r from-gray-900 p-2 text-sm text-white">
      {label}
    </div>
  </div>
)

const ImagePair = ({ inputSrc, outputSrc, altTag }) => (
  <div className="flex  gap-4 rounded-2xl p-3 shadow-lg md:inline-flex">
    {labels.map((label, index) => {
      const src = index === 0 ? inputSrc : outputSrc
      const alt = `${altTag}_${index === 0 ? 'input' : 'output'}`
      return <ImageContainer key={label} src={src} alt={alt} label={label} />
    })}
  </div>
)

export default ImagePair
