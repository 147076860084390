import Image from 'next/image'

const Logo = ({ height, width, className = {} }) => {
  return (
    <Image
      height={height}
      width={width}
      className={className}
      src="/images/logo.svg"
      alt="Mirror AI Logo"
    />
  )
}

export default Logo
